<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
          light
        >
          <v-btn
            icon
            light
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.roles.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            color="primary"
            rounded
            depressed
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-list
          three-line
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t('app.roles.roleName') }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field
                  v-model="item.name"
                  :placeholder="$t('app.roles.roleName')"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t('app.headers.description') }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-textarea
                  v-model="item.description"
                  :placeholder="$t('app.headers.description')"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list
          two-line
          flat
        >
          <v-subheader>
            {{ $t('app.headers.permissions') }}
          </v-subheader>
          <v-list-item-group
            v-model="item.permissions"
            multiple
          >
            <template
              v-for="(module, index) in modules"
            >
              <v-list-item
                :key="`module-${index}`"
              >
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="active"
                      color="primary"
                      @click="toggle"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ module.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ module.description }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <template
                v-if="module.children"
              >
                <v-list-item
                  v-for="(submodule, subindex) in module.children"
                  :key="`submodule-${index}-${subindex}`"
                >
                  <template v-slot:default="{ active, toggle }">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="active"
                        color="primary"
                        @click="toggle"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ submodule.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ submodule.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'RolesAdd',
    data () {
      return {
        dialog: false,
        item: {
          name: '',
          description: '',
          permissions: []
        },
        modal: false,
      }
    },
    computed: {
      ...mapState(['modules'])
    },
    created () {
      EventBus.$on('roles-add', () => {
        this.dialog = true
      })
    },
    methods: {
      async saveItem () {
        this.toggleLoader()

        let permissions = this.modules.filter((module, index) => {
          if (this.item.permissions.includes(index)) {
            return true
          }
        }).map(module => module.to)

        this.item.permissions = permissions

        await this.$http.post(route('v1/role'), this.item)
          .then(result => {
            if (result.body.code === 200) {
              this.processSuccess()
              this.item = {
                name: '',
                description: '',
                permissions: []
              }

              requestAnimationFrame(() => {
                this.$refs.observer.reset()
              })

              this.dialog = false
              EventBus.$emit('reload-items')
            } else {
              this.processError(result.body)
            }
          }, error => {
            this.processError(error)
          })
        this.toggleLoader()
      },
      openItemAdd (item) {
        EventBus.$emit('roles-add', item)
      },
    },
  }
</script>

<style scoped>

</style>
